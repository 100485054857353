import Vue from "vue";
Vue.config.productionTip = true;
import App from "./App.vue";
import router from "../../router";
import store from "./store";
import "vue2-toast/lib/toast.css";
import Toast from "vue2-toast";
import "../../../tailwind.css";

Vue.use(Toast, {
  defaultType: "center",
  duration: 3000,
  wordWrap: true,
  width: "100%",
});

new Vue({
  el: "#app",
  render: (h) => h(App),
  store,
  router,
  toast: Toast,
});
