<template>
  <div id="appMain">
    <div v-if="$store.state.loading" class="sonik-loader-container">
      <div class="sonik-loader"></div>
    </div>
    <router-view />
  </div>
</template>

<script>
// import Spinner from "./components/Spinner";
export default {
  // components: {
  //   Spinner,
  // },
};
</script>

<style lang="scss">
  .sonik-loader-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    width: 100%;
    height: 100vh;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sonik-loader {
    --x: 1s;
    height: 70px;
    width: 57px;
    min-height: 70px;
    min-width: 57px;
    animation: loaderSpin var(--x) linear infinite,
      loaderChange calc(4 * var(--x) / 2) infinite linear calc(var(--x) / 4);
    background-size: contain;
  }

  @keyframes loaderChange {
    0%,
    25% {
      background-image: url("./assets/images/learn.svg");
    }
    25.01%,
    50% {
      background-image: url("./assets/images/earn.svg");
    }
    50.01%,
    75% {
      background-image: url("./assets/images/save.svg");
    }
    75.01%,
    100% {
      background-image: url("./assets/images/spend.svg");
    }
  }

  @keyframes loaderSpin {
    100% {
      transform: rotateY(360deg);
    }
  }

  // Importing Poppins Font Family

  @font-face {
    font-family: Poppins;
    src: url("./assets/fonts/Poppins/Poppins-Black.ttf");
    font-weight: 900;
  }
  @font-face {
    font-family: Poppins;
    src: url("./assets/fonts/Poppins/Poppins-Bold.ttf");
    font-weight: 700;
  }
  @font-face {
    font-family: Poppins;
    src: url("./assets/fonts/Poppins/Poppins-ExtraBold.ttf");
    font-weight: 800;
  }
  @font-face {
    font-family: Poppins;
    src: url("./assets/fonts/Poppins/Poppins-ExtraLight.ttf");
    font-weight: 200;
  }
  @font-face {
    font-family: Poppins;
    src: url("./assets/fonts/Poppins/Poppins-Light.ttf");
    font-weight: 300;
  }
  @font-face {
    font-family: Poppins;
    src: url("./assets/fonts/Poppins/Poppins-Medium.ttf");
    font-weight: 500;
  }
  @font-face {
    font-family: Poppins;
    src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
    font-weight: 400;
  }
  @font-face {
    font-family: Poppins;
    src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf");
    font-weight: 600;
  }
  @font-face {
    font-family: Poppins;
    src: url("./assets/fonts/Poppins/Poppins-Thin.ttf");
    font-weight: 100;
  }

  // Importing Open Sans Font Family

  @font-face {
    font-family: Open-Sans;
    src: url("./assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf");
    font-weight: 800;
  }
  @font-face {
    font-family: Open-Sans;
    src: url("./assets/fonts/Open_Sans/OpenSans-Bold.ttf");
    font-weight: 700;
  }
  @font-face {
    font-family: Open-Sans;
    src: url("./assets/fonts/Open_Sans/OpenSans-SemiBold.ttf");
    font-weight: 600;
  }
  @font-face {
    font-family: Open-Sans;
    src: url("./assets/fonts/Open_Sans/OpenSans-Regular.ttf");
    font-weight: 400;
  }
  @font-face {
    font-family: Open-Sans;
    src: url("./assets/fonts/Open_Sans/OpenSans-Light.ttf");
    font-weight: 300;
  }

  // Importing Fredoka One Font Family

  @font-face {
    font-family: Fredoka;
    src: url("./assets/fonts/Fredoka_One/FredokaOne-Regular.ttf");
    font-weight: 400 !important;
  }

  h1 {
    font-weight: 400 !important;
  }

  :root {
    // --main: #36d5f2;
    --main: #01a4c0;
    --secondary: #7a615a;
    --ternary: #303e65;
    --blueish: #7c8db5;
    --gray: #f2f7ff;
    --red: #ff4f79;
    --cool: #f8f5ed;
    --sans: "Open-Sans", sans-serif;
    --fredoka: "Fredoka", sans-serif;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  button {
    font-family: var(--fredoka) !important;
  }

  body {
    background: #ffffff !important;
    // font-family: "Poppins", sans-serif !important;
    // font-family: var(--fredoka) !important;
    font-family: "Montserrat", sans-serif !important;
    scrollbar-width: none;
    font-size: 16px;
    color: var(--secondary) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility !important;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: transparent;
    // touch-action: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  ::-webkit-scrollbar {
    width: 0px !important;
    background: transparent !important;
    display: none !important;
  }

  #appMain {
    padding: 0;
    height: 100%;
  }

  .loading {
    background: #36d5f2;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    transition: all 300ms ease;
    h1 {
      margin: 0;
      margin-bottom: 5px;
    }
  }

  .loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 9999;
  }

  .page-enter-active,
  .page-leave-active {
    transition: opacity 0.5s;
  }
  .page-enter,
  .page-leave-to {
    opacity: 0;
  }

  .layout-enter-active,
  .layout-leave-active {
    transition: opacity 0.5s;
  }
  .layout-enter,
  .layout-leave-to {
    opacity: 0;
  }

  .slide-bottom-enter-active,
  .slide-bottom-leave-active {
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  }
  .slide-bottom-enter,
  .slide-bottom-leave-to {
    opacity: 0;
    transform: translate3d(0, 15px, 0);
  }
  .bounce-enter-active {
    transform-origin: top;
    animation: bounce-in 0.8s;
  }
  .bounce-leave-active {
    transform-origin: top;
    animation: bounce-out 0.5s;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.25);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes bounce-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.25);
    }
    100% {
      transform: scale(0);
    }
  }
</style>
