import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false
  },
  mutations: {
    TOAST: () => {},
  },
  actions: {
    TOAST({ commit }, msg) {
      Vue.prototype.$toast(msg)
      commit("TOAST", msg);
    },
  },
  modules: {
  }
})
